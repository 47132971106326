const maxValue = ''
// const gradeList = [
//   {
//     text: '全部年级',
//     value: maxValue
//   },
//   {
//     text: '一年级',
//     value: '1'
//   },
//   {
//     text: '二年级',
//     value: '2'
//   },
//   {
//     text: '三年级',
//     value: '3'
//   },
//   {
//     text: '四年级',
//     value: '4'
//   },
//   {
//     text: '五年级',
//     value: '5'
//   },
//   {
//     text: '六年级',
//     value: '6'
//   }
//   ,
//   {
//     text: '七年级',
//     value: '7'
//   },
//   {
//     text: '八年级',
//     value: '8'
//   },
//   {
//     text: '九年级',
//     value: '9'
//   }
// ]
// const clazzList = [
//   {
//     text: '全部班级',
//     value: maxValue
//   },
//   {
//     text: '1班',
//     value: '1'
//   },
//   {
//     text: '2班',
//     value: '2'
//   },
//   {
//     text: '3班',
//     value: '3'
//   },
//   {
//     text: '4班',
//     value: '4'
//   },
//   {
//     text: '5班',
//     value: '5'
//   },
//   {
//     text: '6班',
//     value: '6'
//   }
//   ,
//   {
//     text: '7班',
//     value: '7'
//   },
//   {
//     text: '8班',
//     value: '8'
//   },
//   {
//     text: '9班',
//     value: '9'
//   },
//   {
//     text: '10班',
//     value: '10'
//   },
//   {
//     text: '11班',
//     value: '11'
//   },
//   {
//     text: '12班',
//     value: '12'
//   }
// ]
const sexList = [
  {
    text: '全部',
    value: maxValue
  },
  {
    text: '男',
    value: '1'
  },
  {
    text: '女',
    value: '2'
  }
]
const avatarFlagList = [
  {
    text: '全部',
    value: maxValue
  },
  {
    text: '未上传',
    value: '1'
  },
  {
    text: '已上传',
    value: '2'
  },
  {
    text: '识别度低',
    value: '3'
  }
]
export { sexList, avatarFlagList, maxValue }
