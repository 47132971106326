import _objectSpread from "/Users/yuqingying/Documents/ranJing/project/frontend/school-sport-test-h5-teacher/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import { defineComponent, reactive, toRefs, onBeforeUnmount, onMounted } from 'vue';
import { sexList, avatarFlagList } from '@/constant/gradeAndClassesAndSex';
import { selectType } from '@/constant/constants';
import { mybus, MittTypes } from '@/hooks';
import { useStore } from '@/store';
export default defineComponent({
  props: {
    selectedType: {
      type: Number,
      require: true
    },
    currentValue: {
      type: String,
      default: ''
    }
  },
  components: {},
  setup: function setup(props) {
    var store = useStore();
    var dataMap = reactive({
      popShow: false,
      popTitle: '',
      options: {},
      cascaderValue: ''
    });
    var gradeList = [{
      text: '全部年级',
      value: ''
    }];

    function getGrades() {
      store.dispatch('student/getGrades').then(function (res) {
        res.map(function (option) {
          var tmp = {
            text: option.title,
            value: option.grade,
            groupId: 0
          };
          gradeList.push(tmp);
        });
      });
    }

    var groupAllList;
    var groupList;

    function getGroups() {
      store.dispatch('student/getGroups').then(function (res) {
        groupAllList = res;
      });
    }

    function getGroupsByGradeId(gradeId) {
      groupList = [{
        text: '全部班级',
        value: '',
        groupId: 0
      }];
      groupAllList.map(function (option) {
        if (option.grade == gradeId) {
          option.groups.map(function (group) {
            var tmp = {
              text: group.title,
              value: group.class_number,
              groupId: group.id
            };
            groupList.push(tmp);
          });
        }
      });
    }

    onMounted(function () {
      getGrades();
      getGroups();
      mybus.on(MittTypes.CHANGE_SELECT_TYPE, function (result) {
        var sType = result.selectedType;
        var currentValue = result.currentValue;
        var gradeValue = result.gradeValue;
        getGroupsByGradeId(gradeValue);
        dataMap.popShow = true;
        dataMap.cascaderValue = currentValue;

        switch (sType) {
          case selectType.GRADE:
            dataMap.popTitle = '请选择';
            dataMap.options = gradeList;
            break;

          case selectType.CLAZZ:
            dataMap.popTitle = '请选择';
            dataMap.options = groupList;
            break;

          case selectType.SEX:
            dataMap.popTitle = '请选择';
            dataMap.options = sexList;
            break;

          case selectType.AVATAR_FLAG:
            dataMap.popTitle = '请选择';
            dataMap.options = avatarFlagList;
            break;
        }
      });
    });

    var onClose = function onClose() {
      dataMap.popShow = false;
      dataMap.popTitle = '';
      dataMap.cascaderValue = '';
    };

    var onFinish = function onFinish(_ref) {
      var selectedOptions = _ref.selectedOptions;
      dataMap.popShow = false;
      var result = {
        text: '',
        value: '',
        groupId: 0
      };
      selectedOptions.map(function (option) {
        result.text = option.text;
        result.value = option.value;
        result.groupId = option.groupId;
      });
      mybus.emit(MittTypes.SELECTED_RESULT, result);
    };

    onBeforeUnmount(function () {
      mybus.off(MittTypes.CHANGE_SELECT_TYPE);
    });
    var refData = toRefs(dataMap);
    return _objectSpread(_objectSpread({}, refData), {}, {
      onFinish: onFinish,
      onClose: onClose
    });
  }
});