import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

_pushScopeId("data-v-cad5729c");

var _hoisted_1 = ["src"];

_popScopeId();

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_nav_bar = _resolveComponent("van-nav-bar");

  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      navBarWhite: _ctx.isWhite,
      navBarBlack: _ctx.isBlack
    }, "navBar"])
  }, [_createVNode(_component_van_nav_bar, {
    title: _ctx.title,
    onClickLeft: _ctx.onClickLeft
  }, {
    left: _withCtx(function () {
      return [_createElementVNode("img", {
        src: _ctx.leftImg,
        class: "backImg"
      }, null, 8, _hoisted_1)];
    }),
    right: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default", {}, undefined, true)];
    }),
    _: 3
  }, 8, ["title", "onClickLeft"])], 2);
}