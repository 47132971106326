export function firstToUpper(str: string) {
  if (typeof str != 'undefined') {
    return str.slice(0, 1).toUpperCase() + str.slice(1)
  } else {
    return ''
  }
}
/**
 * @param {object} obj
 * @param {string} key
 * @returns {Boolean}
 */
export function objIsHasKey(obj: any, key: string) {
  if (Object.prototype.hasOwnProperty.call(obj, key)) {
    return true
  } else {
    return false
  }
}

export function isEmpty(str: string | number | any[] | null) {
  if (str === undefined) {
    // 只能用 === 运算来测试某个值是否是未定义的
    return true
  }

  if (str == null) {
    // 等同于 a === undefined || a === null
    return true
  }
  // String
  if (str == '' || str == null || str == undefined) {
    // "",null,undefined
    return true
  }
  if (!str) {
    // "",null,undefined,NaN
    return true
  }
}

/**
 * 自定义函数名：PrefixZero
 * @param num： 被操作数
 * @param n： 固定的总位数
 */
export function prefixZero(num: number, n: number) {
  return (Array(n).join('0') + num).slice(-n)
}

export function avatarUrl(avatarPath: string) {
  if (!isEmpty(avatarPath)) {
    const random = Math.floor(Math.random() * 10)
    return avatarPath + '?t=' + random
  } else {
    return ''
  }
}
export function videoUrl(videoPath: string) {
  if (!isEmpty(videoPath)) {
    return localStorage.getItem('httpUrl') + '/api/media/' + videoPath
  } else {
    return ''
  }
}
export function getCurrentHourAndMinute() {
  const date = new Date()
  const hour = date.getHours()
  const minutes = date.getMinutes()
  return hour + ':' + prefixZero(minutes, 2)
}
export function convertNum(num) {
  let result = ''
  switch (num) {
    case 1:
      result = '一'
      break
    case 2:
      result = '二'
      break
    case 3:
      result = '三'
      break
    case 4:
      result = '四'
      break
    case 5:
      result = '五'
      break
    case 6:
      result = '六'
      break
    case 7:
      result = '七'
      break
    case 8:
      result = '八'
      break
    case 9:
      result = '九'
      break
  }
  return result
}
