import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_cascader = _resolveComponent("van-cascader");

  var _component_van_popup = _resolveComponent("van-popup");

  return _openBlock(), _createBlock(_component_van_popup, {
    show: _ctx.popShow,
    "onUpdate:show": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.popShow = $event;
    }),
    round: "",
    position: "bottom"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_van_cascader, {
        modelValue: _ctx.cascaderValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.cascaderValue = $event;
        }),
        title: _ctx.popTitle,
        options: _ctx.options,
        "active-color": "#2966F7",
        "immediate-check": false,
        "close-on-click-overlay": "",
        "lazy-render": "",
        "close-on-popstate": "",
        "lock-scroll": "",
        onClose: _ctx.onClose,
        onClickOverlay: _ctx.onClose,
        onFinish: _ctx.onFinish
      }, null, 8, ["modelValue", "title", "options", "onClose", "onClickOverlay", "onFinish"])];
    }),
    _: 1
  }, 8, ["show"]);
}