import _objectSpread from "/Users/yuqingying/Documents/ranJing/project/frontend/school-sport-test-h5-teacher/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import { defineComponent, reactive, toRefs, onBeforeUnmount, onMounted } from 'vue';
import { isEmpty, convertNum } from '@/utils/func';
import { useStore } from '@/store';
import { useRouter, useRoute } from 'vue-router';
import { navBar, sexE, selectType } from '@/constant/constants';
import { mybus, MittTypes } from '@/hooks';
import cVanNavBar from '@/components/cVanNavBar.vue';
import cVanPopup from '@/components/cVanPopup.vue';
export default defineComponent({
  props: {},
  components: {
    cVanNavBar: cVanNavBar,
    cVanPopup: cVanPopup
  },
  setup: function setup() {
    var store = useStore();
    var router = useRouter();
    var route = useRoute();
    var dataMap = reactive({
      selectedType: 0,
      navBar: {
        title: '添加个人信息',
        color: navBar.BLACK
      },
      isSelectBoy: true,
      isSelectGirl: false,
      gradeTitle: '',
      clazzTitle: '',
      ruleForm: {
        name: '',
        sex: '1',
        number: '',
        grade: '',
        clazz: '',
        group: 0,
        id: 0
      },
      queryParam: {
        grade: 0,
        clazz: 0,
        sex: 0,
        avatar_flag: 0
      }
    });
    dataMap.queryParam.grade = Number(route.query.grade);
    dataMap.queryParam.clazz = Number(route.query.clazz);
    dataMap.queryParam.sex = Number(route.query.sex);
    dataMap.queryParam.avatar_flag = Number(route.query.avatar_flag);

    var handleSelectedType = function handleSelectedType(result) {
      if (result == selectType.CLAZZ && isEmpty(dataMap.ruleForm.grade)) {
        _Toast('请先选择年级');
      } else {
        dataMap.selectedType = result;
        dataMap.clazzTitle = '';
        var mResult = {
          selectedType: dataMap.selectedType,
          currentValue: '',
          gradeValue: dataMap.ruleForm.grade
        };
        mybus.emit(MittTypes.CHANGE_SELECT_TYPE, mResult);
      }
    };

    onMounted(function () {
      mybus.on(MittTypes.SELECTED_RESULT, function (result) {
        var title = result.text;
        var value = result.value;
        var groupId = result.groupId;

        switch (dataMap.selectedType) {
          case selectType.GRADE:
            dataMap.gradeTitle = title;
            dataMap.ruleForm.grade = value;
            break;

          case selectType.CLAZZ:
            dataMap.clazzTitle = title;
            dataMap.ruleForm.clazz = value;
            dataMap.ruleForm.group = groupId;
            break;
        }
      });
    });
    onBeforeUnmount(function () {
      mybus.off(MittTypes.SELECTED_RESULT);
    });
    dataMap.ruleForm.id = Number(route.query.stuId);

    if (!isEmpty(dataMap.ruleForm.id)) {
      dataMap.navBar.title = '编辑个人信息';
    }

    if (!isEmpty(dataMap.ruleForm.id)) {
      store.dispatch('student/getStudentByIdRequest', {
        id: dataMap.ruleForm.id
      }).then(function (res) {
        dataMap.ruleForm.name = res.name;
        dataMap.ruleForm.sex = String(res.sex);
        dataMap.ruleForm.grade = String(res.grade);
        dataMap.ruleForm.clazz = String(res.clazz);
        dataMap.ruleForm.group = res.group_id;
        dataMap.gradeTitle = convertNum(res.grade) + '年级';
        dataMap.clazzTitle = res.group_title;
        dataMap.ruleForm.number = res.number;
        handleSelectSex(dataMap.ruleForm.sex);
      });
    }

    var onSubmit = function onSubmit(values) {
      if (!isEmpty(dataMap.ruleForm.id)) {
        editStudent();
      } else {
        createStudent();
      }
    };

    var createStudent = function createStudent() {
      store.dispatch('student/createStudentRequest', dataMap.ruleForm).then(function (res) {
        if (res.code == 200) {
          router.push({
            name: 'studentDetail',
            query: {
              stuId: res.data.id
            }
          });
        }
      });
    };

    var editStudent = function editStudent() {
      store.dispatch('student/editStudentRequest', dataMap.ruleForm).then(function (res) {
        if (res.code == 200) {
          router.push({
            name: 'studentDetail',
            query: {
              stuId: dataMap.ruleForm.id,
              grade: dataMap.queryParam.grade,
              clazz: dataMap.queryParam.clazz,
              sex: dataMap.queryParam.sex,
              avatar_flag: dataMap.queryParam.avatar_flag
            }
          });
        }
      });
    };

    var handleSelectSex = function handleSelectSex(sex) {
      switch (sex) {
        case sexE.BOY:
          dataMap.isSelectBoy = true;
          dataMap.isSelectGirl = false;
          break;

        case sexE.GIRL:
          dataMap.isSelectBoy = false;
          dataMap.isSelectGirl = true;
          break;
      }

      dataMap.ruleForm.sex = sex;
    };

    function handleGoBack() {
      router.push({
        name: 'studentList',
        query: {
          grade: dataMap.queryParam.grade,
          clazz: dataMap.queryParam.clazz,
          sex: dataMap.queryParam.sex,
          avatar_flag: dataMap.queryParam.avatar_flag
        }
      });
    }

    var refData = toRefs(dataMap);
    return _objectSpread(_objectSpread({}, refData), {}, {
      sexE: sexE,
      onSubmit: onSubmit,
      handleSelectSex: handleSelectSex,
      handleSelectedType: handleSelectedType,
      selectType: selectType,
      handleGoBack: handleGoBack
    });
  }
});