import mitt from 'mitt'

export default mitt()

export enum MittTypes{
  SELECTED_RESULT = '1',
  CHANGE_SELECT_TYPE = '2',
  SHOW_CAMERA_TIPS = '3',
  CAMERA_SNAPSHOT = '4',
  CAMERA_PHOTO = '5',
  RE_CAMERA_PHOTO = '6',
  CHANGE_CAMERA_FASING_MODE = '7'
}
